import React, { useState } from 'react'
import { GET_SEL_VALUE, OPTION_TYPES } from '../constants'
import { $SurveyInput } from '../Survey.styled'

export const SurveyInput = ({
  type,
  questId,
  customAnswer,
  setCustomAnswer,
  error,
  setError,
  questNumber,
  inputType
}) => {
  const [isError, setIsError] = useState(false)

  const max = ['dob', 'anniversary'].includes(type) ? new Date().toISOString().split('T')[0] : ''

  const getErrorCondition = (value) => {
    switch (inputType) {
      case 'phone':
      case 'number':
        return value?.toString()?.length === 10

      case 'email':
        const regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regEmail.test(String(value).toLowerCase())

      case 'date':
        const regDate = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/
        return regDate.test(String(value).toLowerCase())

      default :
        return true
    }
  }

  const onInputChange = e => {
    if (getErrorCondition(e.target.value)) {
      setError({ ...error, [questId]: '' })
      setIsError(false)
    } else {
      setError({ ...error, [questId]: `Please enter valid ${GET_SEL_VALUE(type, OPTION_TYPES)?.label} for Question No. ${questNumber}` })
      setIsError(true)
    }
    setCustomAnswer(questId, e.target.value)
  }

  return (
    <$SurveyInput
      isError={isError}
      value={customAnswer}
      type={inputType}
      max={max}
      placeholder={'Enter ' + GET_SEL_VALUE(type, OPTION_TYPES)?.label}
      onChange={onInputChange}
    />
  )
}
