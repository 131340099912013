import React from 'react'
import { GET_SEL_VALUE } from '../constants'
import { ReactSelect } from '../ReactSelect'
import { $Block } from '../Survey.styled'

export const Dropdown = ({
  questId,
  selectedOption,
  setOption,
  options,
}) => {

  const OPTIONS = options?.map(opt => ({
    label: opt?.label,
    value: opt?._id || opt?.label,
  })) || []

  return (
    <$Block color="#000" overflowY="visible" height="180px">
      <ReactSelect
        options={OPTIONS}
        onChange={val => setOption(questId, val.value)}
        value={GET_SEL_VALUE(selectedOption, OPTIONS)}
      />
    </$Block>
  )
}
