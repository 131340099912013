import React from "react";
import { $OpinionScaleBox } from "../Survey.styled";

export const OpinionScale = ({
  questId,
  optionId,
  option,
  setOption,
  selectedOption,
  reverse,
  optionsLength,
}) => {
  const isActive = reverse
    ? optionId === selectedOption
    : optionId <= selectedOption;
  const getOptionDimensions = () => {
    let width, height;
    if (optionsLength > 5 && optionsLength <= 11) {
      width = `calc(100% / ${optionsLength} - 8px)`;
      height = "auto";
    } else if (optionsLength > 11) {
      width = `32px`;
    }
    return { height, width };
  };

  const { width, height } = getOptionDimensions();
  return (
    <$OpinionScaleBox
      isActive={isActive}
      onClick={() => setOption(questId, optionId)}
      width={width}
      height={height}
    >
      {option}
    </$OpinionScaleBox>
  );
};
