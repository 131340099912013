import React from 'react'
import { $Rating } from '../Survey.styled'

export const Rating = ({
  questId,
  optionId,
  option,
  setOption,
  selectedOptionValue
}) => {
  const src = Number(selectedOptionValue) < Number(option)
    ? "assets/star-empty.svg"
    : "assets/star-filled.svg"
  return (
    <$Rating onClick={() => setOption(questId, optionId)}>
      <img src={src} title={option} />
    </$Rating>
  )
}
