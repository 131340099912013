import { Survey } from "../components/Survey"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { host } from "../config"
import { useEffect, useState } from "react"
import { $FlexBox, $Message } from "../components/Survey.styled"
import { Loader } from "../components/Loader"
import { SAMPLE_RESPONSE } from "../components/constants"

const Home = () => {
  const notify = (content, type = "info") => toast[type](
    <$FlexBox gap="16px" align="center">
      <img src="/assets/alert.svg" />
      {content}
    </$FlexBox>
  )

  const [response, setResponse] = useState({})
  const [loading, setLoading] = useState(true)
  const [isExternal, setIsExternal] = useState(false)

  const getSurvey = async () => {
    // setLoading(false)
    // return setResponse(SAMPLE_RESPONSE)
    const paramArray = window.location.search.split(/[&?]/)
    const query = {}
    paramArray.forEach(par => {
      if (par?.includes("=")) {
        const [key, value] = par.split("=")
        query[key] = value
      }
    })

    const isRealSurvey = Object.keys(query)?.includes("source")
    const isExternalSurvey = Object.keys(query)?.includes("surveyId")

    setIsExternal(isExternalSurvey)

    if (!(isRealSurvey || isExternalSurvey)) {
      const resp = {
        data: null,
        message: "The link you have provided is Invalid."
      }
      setResponse(resp)
      setLoading(false)
      return
    }

    try {
      const res = await axios.get(`${host}/survey/details`, {
        params: query
      })
      const resp = { data: res.data?.data, params: query, message: res.data?.message }
      setResponse(resp)
      setLoading(false)
    } catch (error) {
      const resp = {
        data: error?.response?.data?.data,
        params: query,
        message: error?.response?.data?.message
      }
      setResponse(resp)
      setLoading(false)
      return error
    }
  }

  const triggerActivity = async () => {
    const [_, param] = window.location.search.split(/[&?]/)
    axios
      .post(`${host}/bills/activity?${param}`, { surveyVisited: true })
      .then(response => console.log(response))
      .catch(err => console.error(err))
  }

  useEffect(() => {
    getSurvey()
    triggerActivity()
  }, [])

  if (loading) {
    return <Loader />
  } else if (response?.data) {
    const { content, contentType } = response.data
    const isMultiPage = contentType !== 'single'
    return (
      <>
        <Survey
          isMultiPage={isMultiPage}
          survey={content}
          params={response.params}
          notify={notify}
          isExternal={isExternal}
        />
        <ToastContainer
          autoClose={2000}
          position="top-center"
        />
      </>
    )
  } else if (response?.message) {
    switch (response?.message) {
      case "survey already submitted":
        return (<$Message>
          Survey Already Submitted.
        </$Message>)

      default:
        return (<$Message>
          {response?.message}
        </$Message>)
    }
  } else {
    return (<$Message>
      Invalid Link
    </$Message>)
  }
}

export default Home
