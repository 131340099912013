import React, { useEffect, useState } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { SurveyHeader, SurveyItem, SurveyImage } from "./surveyComponents";
import {
  $SurveyContainer,
  $Submit,
  $FlexBox,
  $SingleQuest,
  $Message,
  $ImagePage,
  $Block,
} from "./Survey.styled";
import { FILTER_EMPTY, INPUT_TYPES } from "./constants";
import axios from "axios";
import { host } from "../config";
import SurveyProgress from "./surveyComponents/SurveyProgress";

export const Survey = ({ isMultiPage, isExternal, survey, params, notify }) => {
  const [surveyForm, setSurveyForm] = useState({
    responses: [],
  });
  const [mediaUploadSurvey, setMediaUploadSurvey] = useState([]);
  const [error, setError] = useState("");
  const [index, setIndex] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [ignoreQuestionMap, setIgnoreQuestionMap] = useState({});
  const postSurvey = async () => {
    // if (  isExternal) {
    //   return setIsSubmitted(true)
    // }

    const surveyResData = await createPayload({
      reqPayload: {
        ...surveyForm,
        ...params,
      },
      mediaUploadSurvey,
      ignoreQuestionMap,
    });

    try {
      const res = await axios.post(`${host}/survey`, surveyResData);
      if (res) setIsSubmitted(true);

      if (res?.data?.data?.billLink) {
        // apple devices blocks window.open in async call, using setTimeout to run it in main thread.
        setTimeout(() => {
          let url = res?.data?.data?.billLink;
          if (!/^https?:\/\//i.test(url)) {
            const protocol = window.location.protocol;
            url = protocol + "//" + url;
          }
          window.open(url);
        }, 0);
      }
    } catch (error) {
      notify(error?.response?.data?.message);
      console.error(error);
    }
  };

  const reqdQuestIds = survey?.body
    ?.filter(
      (sur) =>
        sur.isRequired &&
        !Object.values(ignoreQuestionMap).flat().includes(sur._id),
    )
    ?.map((surv) => surv._id);

  const respIds = surveyForm?.responses?.map((resp) => resp?.questionId);

  const isNotAnswered = ({ _id, inputType }) => {
    const respObj = surveyForm?.responses?.filter(
      (resp) => _id === resp.questionId,
    )[0];
    if (INPUT_TYPES("multi").includes(inputType)) {
      return respObj?.answerIds?.length === 0;
    } else if (INPUT_TYPES("input").includes(inputType)) {
      return !respObj?.customAnswer;
    } else if (inputType === "location") {
      const [state = "", city = ""] = respObj?.customAnswer?.split(", ");
      return !(!!state && !!city);
    }
  };

  const isReqdAnswered = () => {
    if (reqdQuestIds?.every((id) => respIds.includes(id))) {
      for (let i = 0; i < reqdQuestIds.length; i++) {
        const currentSurvey = survey?.body?.filter(
          (sur) => reqdQuestIds[i] === sur._id,
        )[0];
        if (isNotAnswered(currentSurvey)) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  };

  const isError = Object.keys(FILTER_EMPTY(error));

  const onSubmit = () => {
    if (isError?.length === 0 && isReqdAnswered()) {
      postSurvey(surveyForm);
    }
  };

  const getMarginal = (sect) => {
    const marginal = survey?.marginals?.filter(
      (mar) => mar.section === sect,
    )[0];
    return marginal;
  };

  const quest = survey?.body[index];
  const surveyProps = {
    index,
    quest,
    error,
    notify,
    survey,
    isError,
    respIds,
    setIndex,
    onSubmit,
    setError,
    surveyForm,
    isMultiPage,
    getMarginal,
    reqdQuestIds,
    setSurveyForm,
    setMediaUploadSurvey,
    isNotAnswered,
    isReqdAnswered,
    ignoreQuestionMap,
    setIgnoreQuestionMap,
  };

  if (isSubmitted) {
    return (
      <$Message>
        Your Responses Have Been Submitted.
        <br />
        <br />
        Thank You!
      </$Message>
    );
  } else {
    return <Display {...surveyProps} />;
  }
};

const Display = (props) => {
  const [page, setPage] = useState("welcome");
  const { welcomeImage, exitImage } = props.survey;

  const getDisplay = () => {
    switch (page) {
      case "welcome": {
        if (welcomeImage) {
          return (
            <ImagePage
              survey={props.survey}
              onButtonClick={() => setPage("questionaire")}
              imgSrc={welcomeImage}
            />
          );
        } else {
          setPage("questionaire");
        }
      }

      case "questionaire": {
        const onEndQuestionaire = () => {
          const { isMultiPage, isError, error, isReqdAnswered, notify } = props;

          if (!isMultiPage) {
            if (isError?.length > 0) {
              let elmnt = document.getElementById(isError[0]);
              elmnt?.scrollIntoView({ behavior: "smooth" });
              return notify(error[isError[0]]);
            } else if (!isReqdAnswered()) {
              return notify(
                "Please answer all compulsory questions marked with * before submitting.",
              );
            }
          } else {
            if (isError?.length > 0) {
              return notify(error[isError[0]]);
            } else if (!isReqdAnswered()) {
              return notify(
                "Please answer all compulsory questions marked with * before submitting.",
              );
            }
          }
          if (exitImage) {
            setPage("exit");
          } else {
            props.onSubmit();
          }
        };
        return (
          <Questionaire {...props} onEndQuestionaire={onEndQuestionaire} />
        );
      }

      case "exit": {
        if (exitImage) {
          return (
            <ImagePage
              survey={props.survey}
              onButtonClick={props.onSubmit}
              imgSrc={exitImage}
              buttonText="Submit"
            />
          );
        } else {
          setPage("questionaire");
        }
      }

      default:
        return null;
    }
  };
  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={page}
        addEndListener={(node, done) =>
          node.addEventListener("transitionend", done, false)
        }
        classNames="fade"
      >
        <$Block>{getDisplay()}</$Block>
      </CSSTransition>
    </SwitchTransition>
  );
};

const Questionaire = ({
  index,
  quest,
  error,
  notify,
  survey,
  isError,
  respIds,
  setIndex,
  setError,
  surveyForm,
  isMultiPage,
  getMarginal,
  reqdQuestIds,
  isNotAnswered,
  setSurveyForm,
  setMediaUploadSurvey,
  onEndQuestionaire,
  ignoreQuestionMap,
  setIgnoreQuestionMap,
}) => {
  const nextText =
    survey?.exitImage || index !== survey?.body?.length - 1 ? "Next" : "Submit";

  const totalQuestions =
    survey?.body?.length - Object.values(ignoreQuestionMap).flat().length;
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
  const onPreviousClick = () => {
    let previousIndex = index - 1;
    while (
      Object.values(ignoreQuestionMap)
        .flat()
        .includes(survey?.body?.[previousIndex]?._id) &&
      previousIndex > 0
    ) {
      previousIndex--;
    }
    setIndex(previousIndex);
  };

  const displayQuestNumber = survey?.body
    ?.slice(0, index)
    ?.filter(
      (quest) => !Object.values(ignoreQuestionMap).flat().includes(quest?._id),
    )?.length;

  const onNextClick = () => {
    let nextIndex = index + 1;
    while (
      Object.values(ignoreQuestionMap)
        .flat()
        .includes(survey?.body?.[nextIndex]?._id) &&
      nextIndex < survey?.body?.length - 1
    ) {
      nextIndex++;
    }
    if (index >= survey?.body?.length - 1) {
      setIsSurveySubmitted(true);
      onEndQuestionaire();
    } else if (reqdQuestIds.includes(quest?._id)) {
      if (respIds.includes(quest?._id)) {
        if (isError?.length > 0) {
          notify(error[isError[0]]);
        } else if (isNotAnswered(quest)) {
          notify("You cannot proceed without answering compulsory question.");
        } else {
          setIndex(nextIndex);
        }
      } else {
        notify("You cannot proceed without answering compulsory question.");
      }
    } else if (isError?.length > 0) {
      notify(error[isError[0]]);
    } else {
      setIndex(nextIndex);
    }
  };

  return (
    <$SurveyContainer
      id="surveyCon"
      background={survey?.background}
      fontFamily={survey?.fontFamily}
      fontColor={survey?.fontColor}
    >
      <SurveyImage marginal={getMarginal("header")} />
      <SurveyHeader survey={survey} />
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={index}
          addEndListener={(node, done) =>
            node.addEventListener("transitionend", done, false)
          }
          classNames="fade"
        >
          <div
            style={{
              flex: "1 0 auto",
              overflowX: "hidden",
              overflowY: "visible",
            }}
          >
            {isMultiPage ? (
              <$SingleQuest>
                <SurveyItem
                  key={quest?._id}
                  body={survey?.body}
                  quest={quest}
                  error={error}
                  setError={setError}
                  surveyForm={surveyForm}
                  setSurveyForm={setSurveyForm}
                  setMediaUploadSurvey={setMediaUploadSurvey}
                  ignoreQuestionMap={ignoreQuestionMap}
                  setIgnoreQuestionMap={setIgnoreQuestionMap}
                />
              </$SingleQuest>
            ) : (
              survey?.body?.map((item) => (
                <SurveyItem
                  key={item._id}
                  body={survey?.body}
                  quest={item}
                  error={error}
                  setError={setError}
                  surveyForm={surveyForm}
                  setSurveyForm={setSurveyForm}
                  setMediaUploadSurvey={setMediaUploadSurvey}
                  ignoreQuestionMap={ignoreQuestionMap}
                  setIgnoreQuestionMap={setIgnoreQuestionMap}
                />
              ))
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <$FlexBox justify="center" align="center" direction="column">
        {isMultiPage ? (
          <$FlexBox justify="center" align="center" gap="20px">
            <$Submit
              bgColor={survey?.buttonConfig?.bgColor}
              fontWeight={survey?.buttonConfig?.fontWeight}
              textColor={survey?.buttonConfig?.textColor}
              fontFamily={survey?.fontFamily}
              disabled={index === 0}
              onClick={onPreviousClick}
            >
              Previous
            </$Submit>
            <$Submit
              bgColor={survey?.buttonConfig?.bgColor}
              fontWeight={survey?.buttonConfig?.fontWeight}
              textColor={survey?.buttonConfig?.textColor}
              fontFamily={survey?.fontFamily}
              onClick={onNextClick}
            >
              {nextText}
            </$Submit>
          </$FlexBox>
        ) : (
          <$Submit
            bgColor={survey?.buttonConfig?.bgColor}
            fontWeight={survey?.buttonConfig?.fontWeight}
            textColor={survey?.buttonConfig?.textColor}
            fontFamily={survey?.fontFamily}
            disabled={surveyForm?.responses?.length === 0}
            isSinglePage={!isMultiPage}
            onClick={onEndQuestionaire}
          >
            {survey?.exitImage ? "Next" : "Submit"}
          </$Submit>
        )}
        <SurveyImage marginal={getMarginal("footer")} />
      </$FlexBox>
      {survey?.showProgressBar ? (
        <SurveyProgress
          isComplete={isSurveySubmitted}
          currentStep={displayQuestNumber}
          totalSteps={totalQuestions}
        />
      ) : null}
    </$SurveyContainer>
  );
};

const ImagePage = ({
  survey,
  imgSrc,
  onButtonClick,
  buttonText = "Take Survey",
}) => {
  return (
    <$ImagePage>
      <img src={imgSrc} />
      <$Submit
        bgColor={survey?.buttonConfig?.bgColor}
        fontWeight={survey?.buttonConfig?.fontWeight}
        textColor={survey?.buttonConfig?.textColor}
        fontFamily={survey?.fontFamily}
        onClick={onButtonClick}
      >
        {buttonText}
      </$Submit>
    </$ImagePage>
  );
};

// helper
const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve({ fileName: file.name, fileData: fileReader.result });
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

const createPayload = async ({
  reqPayload = {},
  mediaUploadSurvey = [],
  ignoreQuestionMap = {},
}) => {
  const ignoreQuestionIds = Object.values(ignoreQuestionMap).flat();
  for (const item of mediaUploadSurvey) {
    const { questionId, answerFiles } = item;
    const resObj = {
      questionId,
      answerFiles: [],
    };

    for (const answerFile of answerFiles) {
      const fileData = await convertBase64(answerFile);
      resObj.answerFiles.push(fileData);
    }

    const existingQuestion = reqPayload.responses.findIndex(
      (ele) => ele.questionId === questionId,
    );

    if (existingQuestion > -1) {
      reqPayload.responses[existingQuestion].answerFiles = resObj.answerFiles;
    } else {
      reqPayload.responses.push(resObj);
    }
  }
  reqPayload.responses = reqPayload.responses.filter(
    (res) => !ignoreQuestionIds.includes(res.questionId),
  );

  return reqPayload;
};
