import React from 'react'
import { Dropdown } from './dropdown'
import { MediaUpload } from './mediaUpload'
import { ImageSelect } from './image'
import { Location } from './location'
import { OpinionScale } from './OpinionScale'
import { Radio } from './Radio'
import { Rating } from './rating'
import { SurveyInput } from './SurveyInput'

export const SurveyOption = (props) => {
  switch (props.type) {
    case 'radio':
    case 'yesorno':
    case 'gender':
    case 'serviceExplicit':
      return <Radio {...props} inputType="radio" />

    case 'checkbox':
      return <Radio {...props} inputType="checkbox" />

    case 'range':
      return <OpinionScale {...props} />

    case 'rating':
      return <Rating {...props} />

    case 'image':
    case 'image-multi':
      return <ImageSelect {...props} />

    case 'text':
    case 'companyName':
    case 'firstName':
    case 'lastName':
    case 'spouseName':
    case 'profession':
      return <SurveyInput {...props} inputType="text" />

    case 'date':
    case 'dob':
    case 'anniversary':
      return <SurveyInput {...props} inputType="date" />

    case 'phone':
      return <SurveyInput {...props} inputType="number" />

    case 'email':
      return <SurveyInput {...props} inputType="email" />

    case 'location':
      return <Location {...props} />

    case 'dropdown':
      return <Dropdown {...props} />

      case 'mediaUpload':
        return <MediaUpload {...props} />

    default:
      return null
  }
}
