import { React, useState, useRef, useEffect } from "react";
import { $Block, $FlexBox, $QuestContainer } from "../Survey.styled";
import {
  DESCRIPTION_STYLE,
  INPUT_TYPES,
  QUEST_NUMBER_STYLE,
  SURVEY_STYLE,
} from "../constants";
import { SurveyOption } from "../options";

export const SurveyQuestion = ({
  quest,
  questNumber,
  surveyForm,
  setSurveyForm,
  error,
  setError,
  setMediaUploadSurvey,
  ignoreQuestionMap,
  setIgnoreQuestionMap,
  displayQuestNumber,
}) => {
  // Constants
  const isInputType = INPUT_TYPES("input").includes(quest?.inputType);
  const isDropDown = ["dropdown"].includes(quest?.inputType);
  const isMediaUpload = ["mediaUpload"].includes(quest?.inputType);
  const isLocation = ["location"].includes(quest?.inputType);
  const isMultiType = INPUT_TYPES("multi").includes(quest?.inputType);
  const isRating = quest?.inputType === "rating";
  const questIdArray = surveyForm.responses?.map((res) => res?.questionId);

  const [images, setImages] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef(null);

  const setCustomAnswer = (questionId, customAnswer) => {
    if (questIdArray.includes(questionId)) {
      const responses = surveyForm.responses?.map((res) => {
        if (res.questionId === questionId) {
          res.customAnswer = customAnswer;
        }
        return res;
      });
      setSurveyForm({ ...surveyForm, responses });
    } else {
      setSurveyForm({
        ...surveyForm,
        responses: [...surveyForm?.responses, { questionId, customAnswer }],
      });
    }
  };

  const getCustomAnswer = (questionId) => {
    const answer =
      surveyForm.responses?.filter((res) => res.questionId === questionId)[0]
        ?.customAnswer || "";
    return answer;
  };

  const getSelectedOption = () => {
    const answer =
      surveyForm.responses?.filter((res) => res.questionId === quest?._id)[0]
        ?.answerIds || "";
    return isMultiType ? answer : answer[0] || "";
  };

  const getSelectedOptionValue = () => {
    const answerValue =
      quest?.options?.filter((res) => res._id === getSelectedOption())[0]
        ?.label || "";
    return answerValue;
  };

  const setOption = (questionId, answerId) => {
    const questionsIgnored = [answerId]
      .map(
        (answerId) =>
          quest?.options?.find((option) => option?._id === answerId)
            ?.ignore_list
      )
      ?.flat()
      ?.filter((x) => x);
    const tempIgnoreQuestionMap = { ...ignoreQuestionMap };

    tempIgnoreQuestionMap[questionId] = questionsIgnored;
    setIgnoreQuestionMap(tempIgnoreQuestionMap);
    if (questIdArray.includes(questionId)) {
      const responses = surveyForm.responses?.map((res) => {
        if (res.questionId === questionId) {
          res.answerIds = [answerId];
        }
        return res;
      });
      setSurveyForm({ ...surveyForm, responses });
    } else {
      setSurveyForm({
        ...surveyForm,
        responses: [
          ...surveyForm?.responses,
          { questionId, answerIds: [answerId] },
        ],
      });
    }
  };

  const setOptions = (questionId, answerIds) => {
    const questionsIgnored = answerIds
      .map(
        (answerId) =>
          quest?.options?.find((option) => option?._id === answerId)
            ?.ignore_list
      )
      ?.flat();
    const tempIgnoreQuestionMap = { ...ignoreQuestionMap };
    tempIgnoreQuestionMap[questionId] = questionsIgnored;
    setIgnoreQuestionMap(tempIgnoreQuestionMap);

    if (questIdArray.includes(questionId)) {
      const responses = surveyForm.responses?.map((res) => {
        if (res.questionId === questionId) {
          res.answerIds = answerIds;
        }
        return res;
      });
      setSurveyForm({ ...surveyForm, responses });
    } else {
      setSurveyForm({
        ...surveyForm,
        responses: [...surveyForm?.responses, { questionId, answerIds }],
      });
    }
  };

  const handleAddImageClick = () => {
    fileInputRef.current.click();
  };

  const removeImage = async (index, questionId) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    if (newImages.length <= 4) {
      setDisabled(false);
    }
    setImages(() => {
      const stateRes = newImages;
      setMediaUploadSurvey((surveyImages) => {
        if (questIdArray.includes(questionId)) {
          if (surveyImages) {
            const responses = surveyImages?.map((res) => {
              if (res.questionId === questionId) {
                res.answerFiles = stateRes;
              }
              return res;
            });
            let response = surveyForm?.responses;
            let findIndexSurveyForm = response.findIndex(
              (x) => x.questionId == questionId
            );
            if (findIndexSurveyForm > -1) {
              response[findIndexSurveyForm]["answerFiles"] = stateRes;
            }
            setSurveyForm({ ...surveyForm, response });
            return responses;
          }
        }
      });
      return stateRes;
    });
    setImages(newImages);
  };

  const debounceErrorMsg = () => {
    setTimeout(() => setShowErrorMsg(false), 3000);
  };

  const handleImageChange = async (e, questionId) => {
    const files = Array.from(e.target.files);
    setErrorMessage("");

    // Check if the total size of files is within 10MB
    const totalSize = files.reduce((acc, file) => acc + file.size, 0);
    if (totalSize > 10 * 1024 * 1024) {
      setErrorMessage("Total size exceeds 10MB.");
      setShowErrorMsg(true);
      debounceErrorMsg();
      return;
    }

    // Check if the number of files is within 5
    if (images.length + files.length > 5) {
      setErrorMessage("You can select only 5 images.");
      setShowErrorMsg(true);
      setDisabled(true);
      debounceErrorMsg();
      if (images.length + files.length >= 4) {
        setDisabled(false);
      }
      return;
    } else {
      setDisabled(false);
    }

    if (images.length + files.length >= 5) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }

    // Check if all files are of supported types
    const unsupportedFiles = files.filter(
      (file) => !["image/jpeg", "image/jpg", "image/png"].includes(file.type)
    );
    if (unsupportedFiles.length > 0) {
      setErrorMessage("Only JPEG, JPG, and PNG files are supported.");
      setShowErrorMsg(true);
      debounceErrorMsg();
      return;
    }
    setImages((stateFiles) => {
      const stateRes = [...stateFiles, ...files];
      setMediaUploadSurvey((surveyImages) => {
        if (questIdArray.includes(questionId)) {
          if (surveyImages) {
            const responses = surveyImages?.map((res) => {
              if (res.questionId === questionId) {
                res.answerFiles = stateRes;
              }
              return res;
            });
            let response = surveyForm?.responses;
            let findIndexSurveyForm = response.findIndex(
              (x) => x.questionId == questionId
            );
            if (findIndexSurveyForm > -1) {
              response[findIndexSurveyForm]["answerFiles"] = stateRes;
            }
            setSurveyForm({ ...surveyForm, response });
            return responses;
          }
        } else {
          if (!surveyImages || !surveyImages.length) {
            let responses = [{ questionId, answerFiles: stateRes }];
            setSurveyForm({
              ...surveyForm,
              responses: [
                ...surveyForm?.responses,
                { questionId, answerFiles: stateRes },
              ],
            });
            return responses;
          }
        }
      });
      return stateRes;
    });
  };

  const required =
    '<span style="color: tomato;margin: 0;top: -5px;position: relative;font-weight: 500;">*</span>';
  const options = quest?.reverse
    ? quest?.options?.slice()?.reverse()
    : quest?.options;

  return (
    <$QuestContainer>
      <$FlexBox align="flex-start" justify="flex-start">
        <p style={QUEST_NUMBER_STYLE}>{displayQuestNumber}.</p>
        <p
          id={quest?._id}
          dangerouslySetInnerHTML={{
            __html: `${quest?.text}${quest?.isRequired ? required : ""}`,
          }}
          style={SURVEY_STYLE}
        />
      </$FlexBox>
      {quest?.description && (
        <p style={DESCRIPTION_STYLE}>{quest?.description}</p>
      )}
      <$FlexBox align="center">
        <$Block padding="10px 20px" width="100%">
          {!isInputType &&
            !isDropDown &&
            !isMediaUpload &&
            !isLocation &&
            options?.map((opt, i) => (
              <SurveyOption
                key={opt._id}
                optId={i}
                optionId={opt._id}
                optionsLength={quest?.options?.length}
                questId={quest?._id}
                option={opt?.label}
                imageTitle={opt?.title}
                showTitle={quest?.showTitle}
                type={quest?.inputType}
                selectedOption={getSelectedOption()}
                selectedOptionValue={isRating ? getSelectedOptionValue() : ""}
                setOption={isMultiType ? setOptions : setOption}
                setError={setError}
                reverse={quest?.reverse}
              />
            ))}
          {(isInputType || isLocation) && (
            <SurveyOption
              questId={quest?._id}
              type={quest?.inputType}
              setCustomAnswer={setCustomAnswer}
              customAnswer={getCustomAnswer(quest?._id)}
              error={error}
              setError={setError}
              questNumber={questNumber}
            />
          )}
          {isDropDown && (
            <SurveyOption
              questId={quest?._id}
              type={quest?.inputType}
              selectedOption={getSelectedOption()}
              error={error}
              setError={setError}
              questNumber={questNumber}
              options={quest?.options}
              setOption={setOption}
            />
          )}
          {isMediaUpload && (
            <SurveyOption
              type={quest?.inputType}
              handleImageChange={(e) => handleImageChange(e, quest?._id)}
              handleAddImageClick={handleAddImageClick}
              removeImage={removeImage}
              errorMessage={errorMessage}
              fileInputRef={fileInputRef}
              images={images}
              disabled={disabled}
              showErrorMsg={showErrorMsg}
              questionId={quest?._id}
            />
          )}
        </$Block>
      </$FlexBox>
    </$QuestContainer>
  );
};
