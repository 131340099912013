import React from 'react'
import { GET_CITY_OPTIONS, GET_SEL_VALUE, STATE_OPTIONS } from '../constants'
import { ReactSelect } from '../ReactSelect'
import { $Block } from '../Survey.styled'

export const Location = ({
  questId,
  setCustomAnswer,
  customAnswer,
}) => {
  const [state = '', city = ''] = customAnswer?.split(', ') || ['', '']

  return (
    <$Block color="#000" height="220px">
      <$Block margin="0 0 20px 0">
        <ReactSelect
          options={STATE_OPTIONS}
          onChange={(val) => setCustomAnswer(questId, val.value)}
          value={GET_SEL_VALUE(state, STATE_OPTIONS)}
          placeholder="Select State"
        />
      </$Block>
      <$Block margin="0 0 20px 0">
        <ReactSelect
          disabled={!state}
          options={GET_CITY_OPTIONS(state)}
          onChange={(val) => setCustomAnswer(questId, `${state}, ${val.value}`)}
          value={city ? GET_SEL_VALUE(city, GET_CITY_OPTIONS(state) || []) : ''}
          placeholder="Select City"
        />
      </$Block>
    </$Block>
  )
}
