export const GET_SEL_VALUE = (val, array) =>
  array.filter((opt) => opt.value === val)[0]

export const OPTION_TYPES = [
  {
    label: 'Single Select',
    value: 'radio',
  },
  {
    label: 'Yes/No',
    value: 'yesorno',
  },
  {
    label: 'Gender',
    value: 'gender',
  },
  {
    label: 'Service Explicit',
    value: 'serviceExplicit',
  },
  {
    label: 'Multi Select',
    value: 'checkbox',
  },
  {
    label: 'Image Single Select',
    value: 'image',
  },
  {
    label: 'Image Multi Select',
    value: 'image-multi',
  },
  {
    label: 'Text Answer',
    value: 'text',
  },
  {
    label: 'Phone Number',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'Date of Birth',
    value: 'dob',
  },
  {
    label: 'Anniversary Date',
    value: 'anniversary',
  },
  {
    label: 'Company Name',
    value: 'companyName',
  },
  {
    label: 'First Name',
    value: 'firstName',
  },
  {
    label: 'Spouse Name',
    value: 'spouseName',
  },
  {
    label: 'Last Name',
    value: 'lastName',
  },
  {
    label: 'Profession',
    value: 'profession',
  },
  {
    label: 'Opinion Scale',
    value: 'range',
  },
  {
    label: 'Rating',
    value: 'rating',
  },
  {
    label: 'Dropdown',
    value: 'dropdown',
  },
  {
    label: 'Location',
    value: 'location',
  },
]  

export const SHOW_ADD = ['radio', 'checkbox', 'yesorno']

export const SURVEY_STYLE = {
  padding: '6px',
  borderRadius: '4px',
  margin: '10px 0 0'
}
export const DESCRIPTION_STYLE = { fontWeight: 'normal', fontSize: '14px', marginLeft: '20px' }
export const QUEST_NUMBER_STYLE = { maxWidth: '25px', width: '25px', textAlign: 'right', margin: '10px 0 0', padding: '6px 0' }

export const OPINION_STYLE = {
  position: 'relative',
  cursor: 'pointer',
  padding: '0 10px',
  color: 'tomato',
  display: 'inline-flex',
  alignItems: 'center',
  height: '40px',
}

export const FILTER_EMPTY = (obj) => {
  let finalObj = {}
  obj &&
    Object.keys(obj).forEach((key) => {
      if (Array.isArray(obj[key]) && obj[key].length !== 0) {
        finalObj[key] = obj[key]
      } else if (obj[key] && typeof obj[key] === 'object') {
        const nestedObj = FILTER_EMPTY(obj[key])
        if (obj[key] && Array.isArray(obj[key])) {
          finalObj[key] = nestedObj
        } else if (Object.keys(nestedObj).length) {
          finalObj[key] = nestedObj
        }
      } else if (
        obj[key] !== '' &&
        obj[key] !== undefined &&
        obj[key] !== null &&
        !(Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        finalObj[key] = obj[key]
      }
    })
  return finalObj
}

export const SAMPLE_RESPONSE = {
  data: {
    "_id": "611cabda609407001396ce47",
    "content": {
      "header": "Survey Header",
      "description": "Survey Description",
      "background": "#050505",
      "body": [
        {
          "_id": "611cae4f609407001396ce53",
          "text": "Single Select",
          "isRequired": true,
          "fieldType": "question",
          "description": "Single Description",
          "inputType": "radio",
          "options": [
            {
              "_id": "611cae4f609407001396ce54",
              "label": "Option 1"
            },
            {
              "_id": "611cae4f609407001396ce55",
              "label": "Option 2"
            },
            {
              "_id": "611cae4f609407001396ce56",
              "label": "Option 3"
            },
            {
              "_id": "611cae4f609407001396ce57",
              "label": "Option 4"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce58",
          "text": "Multi Select",
          "isRequired": true,
          "fieldType": "question",
          "description": "Multi Description",
          "inputType": "checkbox",
          "options": [
            {
              "_id": "611cae4f609407001396ce59",
              "label": "Option 1"
            },
            {
              "_id": "611cae4f609407001396ce5a",
              "label": "Option 2"
            },
            {
              "_id": "611cae4f609407001396ce5b",
              "label": "Option 3"
            },
            {
              "_id": "611cae4f609407001396ce5c",
              "label": "Option 4"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce5d",
          "isRequired": true,
          "fieldType": "question",
          "text": "Yes Or No",
          "description": null,
          "inputType": "yesorno",
          "options": [
            {
              "_id": "611cae4f609407001396ce5e",
              "label": "Yes"
            },
            {
              "_id": "611cae4f609407001396ce5f",
              "label": "No"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce60",
          "isRequired": true,
          "fieldType": "question",
          "text": "What is your gender?",
          "description": "",
          "inputType": "gender",
          "options": [
            {
              "_id": "611cae4f609407001396ce61",
              "label": "Male"
            },
            {
              "_id": "611cae4f609407001396ce62",
              "label": "Female"
            },
            {
              "_id": "611cae4f609407001396ce63",
              "label": "Other"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce64",
          "isRequired": true,
          "fieldType": "question",
          "text": "Would you like to receive messages or mails about promotions, events and offers?",
          "description": "",
          "inputType": "serviceExplicit",
          "options": [
            {
              "_id": "611cae4f609407001396ce65",
              "label": "Yes"
            },
            {
              "_id": "611cae4f609407001396ce66",
              "label": "No"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce67",
          "text": "Would you like to receive messages or mails about promotions, events and offers?",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "showTitle": false,
          "inputType": "image",
          "options": [
            {
              "_id": "611cae4f609407001396ce68",
              "label": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/37737df583993707edc5b397e577e7ec_1627296683959.png",
              "title": "1"
            },
            {
              "_id": "611cae4f609407001396ce69",
              "label": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/74ffbffc76fb9ad8fcf81950c2f7f4e4_1625567232619.jpeg",
              "title": "2"
            },
            {
              "_id": "611cae4f609407001396ce6a",
              "label": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/a95def07c768d87098631577bc8035ba_1623391209622.png",
              "title": "3"
            },
            {
              "_id": "611cae4f609407001396ce6b",
              "label": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/579e2c68f0d78965336ca466639e4d98_1623386320071.jpg",
              "title": "4"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce6c",
          "text": "Would you like to receive messages or mails about promotions, events and offers?",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "showTitle": true,
          "inputType": "image-multi",
          "options": [
            {
              "_id": "611cae4f609407001396ce6d",
              "label": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/a2d70be3576338225c0f0a94c46e07bf_1613138659301.jpeg",
              "title": "Raghuveer Bharadwaj 1"
            },
            {
              "_id": "611cae4f609407001396ce6e",
              "label": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/38e711b4c3724609a14db007b03b432c_1613135470297.jpeg",
              "title": "Raghuveer Bharadwaj 2"
            },
            {
              "_id": "611cae4f609407001396ce6f",
              "label": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/00437b5017059779898c58a3157d6462_1613120077226.png",
              "title": "Raghuveer Bharadwaj 3"
            },
            {
              "_id": "611cae4f609407001396ce70",
              "label": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/e38a7609c5b473f9153c4fc105e25c20_1613119849337.png",
              "title": "Raghuveer Bharadwaj 4"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce71",
          "text": "Would you like to receive messages or mails about promotions, events and offers?",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "showTitle": true,
          "inputType": "text",
          "options": []
        },
        {
          "_id": "611cae4f609407001396ce72",
          "text": "Phone Number",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "showTitle": true,
          "inputType": "phone",
          "options": []
        },
        {
          "_id": "611cae4f609407001396ce73",
          "text": "Profession",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "inputType": "profession",
          "options": [
            {
              "_id": "611cae4f609407001396ce74",
              "label": ""
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce75",
          "text": "DOB",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "inputType": "dob",
          "options": [
            {
              "_id": "611cae4f609407001396ce76",
              "label": ""
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce77",
          "text": "Opinion Scale",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "inputType": "range",
          "options": [
            {
              "_id": "611cae4f609407001396ce78",
              "label": "1"
            },
            {
              "_id": "611cae4f609407001396ce79",
              "label": "2"
            },
            {
              "_id": "611cae4f609407001396ce7a",
              "label": "3"
            },
            {
              "_id": "611cae4f609407001396ce7b",
              "label": "4"
            },
            {
              "_id": "611cae4f609407001396ce7c",
              "label": "5"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce7d",
          "text": "Rating",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "inputType": "rating",
          "options": [
            {
              "_id": "611cae4f609407001396ce7e",
              "label": "1"
            },
            {
              "_id": "611cae4f609407001396ce7f",
              "label": "2"
            },
            {
              "_id": "611cae4f609407001396ce80",
              "label": "3"
            },
            {
              "_id": "611cae4f609407001396ce81",
              "label": "4"
            },
            {
              "_id": "611cae4f609407001396ce82",
              "label": "5"
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce83",
          "text": "Location",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "inputType": "location",
          "options": [
            {
              "_id": "611cae4f609407001396ce84",
              "label": ""
            }
          ]
        },
        {
          "_id": "611cae4f609407001396ce85",
          "text": "Dropdown",
          "isRequired": true,
          "fieldType": "question",
          "description": "",
          "inputType": "dropdown",
          "options": [
            {
              "_id": "611cae4f609407001396ce86",
              "label": "First Option"
            },
            {
              "_id": "611cae4f609407001396ce87",
              "label": "Second Option"
            },
            {
              "_id": "611cae4f609407001396ce88",
              "label": "Third Option"
            },
            {
              "_id": "611cae4f609407001396ce89",
              "label": "Fourth Option"
            }
          ]
        }
      ],
      "marginals": [
        {
          "_id": "611cae71609407001396ce8a",
          "section": "header",
          "url": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/e7686baeb40f97968c76260d78b9f05d_1629272554273.png"
        }
      ],
      "welcomeImage": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/576ffba0ae228c3644119350cfcb21eb_1613125813591.jpg",
      "exitImage": "https://d3r5icmkm2palf.cloudfront.net/assets/nygrw1khk6j78bq2/b2cdc6605eb041c08fcfcb7a1bec24cd_1613125740232.png",
      "fontFamily": "Times New Roman, Times, serif",
      "fontColor": "#e9eef3",
      "buttonConfig": {
        "fontWeight": "normal",
        "textColor": "#0b0c0c",
        "bgColor": "#d9e3f0"
      }
    },
    "campaignType": "survey",
    "isActive": true,
    "status": "draft",
    "name": "All Type of Quests",
    "vendorId": "nygrw1khk6j78bq2",
    "createdBy": "5e43d9d3ce97de6396171a36",
    "createdDate": "2021-08-18T06:42:34.150Z",
    "updatedDate": "2021-08-18T06:55:35.762Z",
    "__v": 0,
    "contentType": "single"
  }
}

export const CITIES = {
  'Andhra Pradesh (AP)': [
    'Adilabad',
    'Anantapur',
    'Chittoor',
    'Kakinada',
    'Guntur',
    'Hyderabad',
    'Karimnagar',
    'Khammam',
    'Krishna',
    'Kurnool',
    'Mahbubnagar',
    'Medak',
    'Nalgonda',
    'Nizamabad',
    'Ongole',
    'Hyderabad',
    'Srikakulam',
    'Nellore',
    'Visakhapatnam',
    'Vizianagaram',
    'Warangal',
    'Eluru',
    'Kadapa',
  ],
  'Arunachal Pradesh (AR)': [
    'Anjaw',
    'Changlang',
    'East Siang',
    'Kurung Kumey',
    'Lohit',
    'Lower Dibang Valley',
    'Lower Subansiri',
    'Papum Pare',
    'Tawang',
    'Tirap',
    'Dibang Valley',
    'Upper Siang',
    'Upper Subansiri',
    'West Kameng',
    'West Siang',
  ],
  'Assam (AS)': [
    'Baksa',
    'Barpeta',
    'Bongaigaon',
    'Cachar',
    'Chirang',
    'Darrang',
    'Dhemaji',
    'Dima Hasao',
    'Dhubri',
    'Dibrugarh',
    'Goalpara',
    'Golaghat',
    'Hailakandi',
    'Jorhat',
    'Kamrup',
    'Kamrup Metropolitan',
    'Karbi Anglong',
    'Karimganj',
    'Kokrajhar',
    'Lakhimpur',
    'Marigaon',
    'Nagaon',
    'Nalbari',
    'Sibsagar',
    'Sonitpur',
    'Tinsukia',
    'Udalguri',
  ],
  'Bihar (BR)': [
    'Araria',
    'Arwal',
    'Aurangabad',
    'Banka',
    'Begusarai',
    'Bhagalpur',
    'Bhojpur',
    'Buxar',
    'Darbhanga',
    'East Champaran',
    'Gaya',
    'Gopalganj',
    'Jamui',
    'Jehanabad',
    'Kaimur',
    'Katihar',
    'Khagaria',
    'Kishanganj',
    'Lakhisarai',
    'Madhepura',
    'Madhubani',
    'Munger',
    'Muzaffarpur',
    'Nalanda',
    'Nawada',
    'Patna',
    'Purnia',
    'Rohtas',
    'Saharsa',
    'Samastipur',
    'Saran',
    'Sheikhpura',
    'Sheohar',
    'Sitamarhi',
    'Siwan',
    'Supaul',
    'Vaishali',
    'West Champaran',
    'Chandigarh',
  ],
  'Chhattisgarh (CG)': [
    'Bastar',
    'Bijapur',
    'Bilaspur',
    'Dantewada',
    'Dhamtari',
    'Durg',
    'Jashpur',
    'Janjgir-Champa',
    'Korba',
    'Koriya',
    'Kanker',
    'Kabirdham (Kawardha)',
    'Mahasamund',
    'Narayanpur',
    'Raigarh',
    'Rajnandgaon',
    'Raipur',
    'Surguja',
  ],
  'Dadra and Nagar Haveli (DN)': ['Dadra and Nagar Haveli'],
  'Daman and Diu (DD)': ['Daman', 'Diu'],
  'Delhi (DL)': [
    'Central Delhi',
    'East Delhi',
    'New Delhi',
    'North Delhi',
    'North East Delhi',
    'North West Delhi',
    'South Delhi',
    'South West Delhi',
    'West Delhi',
  ],
  'Goa (GA)': ['North Goa', 'South Goa'],
  'Gujarat (GJ)': [
    'Ahmedabad',
    'Amreli district',
    'Anand',
    'Banaskantha',
    'Bharuch',
    'Bhavnagar',
    'Dahod',
    'The Dangs',
    'Gandhinagar',
    'Jamnagar',
    'Junagadh',
    'Kutch',
    'Kheda',
    'Mehsana',
    'Narmada',
    'Navsari',
    'Patan',
    'Panchmahal',
    'Porbandar',
    'Rajkot',
    'Sabarkantha',
    'Surendranagar',
    'Surat',
    'Vyara',
    'Vadodara',
    'Valsad',
  ],
  'Haryana (HR)': [
    'Ambala',
    'Bhiwani',
    'Faridabad',
    'Fatehabad',
    'Gurgaon',
    'Hissar',
    'Jhajjar',
    'Jind',
    'Karnal',
    'Kaithal',
    'Kurukshetra',
    'Mahendragarh',
    'Mewat',
    'Palwal',
    'Panchkula',
    'Panipat',
    'Rewari',
    'Rohtak',
    'Sirsa',
    'Sonipat',
    'Yamuna Nagar',
  ],
  'Himachal Pradesh (HP)': [
    'Bilaspur',
    'Chamba',
    'Hamirpur',
    'Kangra',
    'Kinnaur',
    'Kullu',
    'Lahaul and Spiti',
    'Mandi',
    'Shimla',
    'Sirmaur',
    'Solan',
    'Una',
  ],
  'Jammu and Kashmir (JK)': [
    'Anantnag',
    'Badgam',
    'Bandipora',
    'Baramulla',
    'Doda',
    'Ganderbal',
    'Jammu',
    'Kargil',
    'Kathua',
    'Kishtwar',
    'Kupwara',
    'Kulgam',
    'Leh',
    'Poonch',
    'Pulwama',
    'Rajauri',
    'Ramban',
    'Reasi',
    'Samba',
    'Shopian',
    'Srinagar',
    'Udhampur',
  ],
  'Jharkhand (JH)': [
    'Bokaro',
    'Chatra',
    'Deoghar',
    'Dhanbad',
    'Dumka',
    'East Singhbhum',
    'Garhwa',
    'Giridih',
    'Godda',
    'Gumla',
    'Hazaribag',
    'Jamtara',
    'Khunti',
    'Koderma',
    'Latehar',
    'Lohardaga',
    'Pakur',
    'Palamu',
    'Ramgarh',
    'Ranchi',
    'Sahibganj',
    'Seraikela Kharsawan',
    'Simdega',
    'West Singhbhum',
  ],
  'Karnataka (KA)': [
    'Bagalkot',
    'Bangalore Rural',
    'Bangalore Urban',
    'Belgaum',
    'Bellary',
    'Bidar',
    'Bijapur',
    'Chamarajnagar',
    'Chikkamagaluru',
    'Chikkaballapur',
    'Chitradurga',
    'Davanagere',
    'Dharwad',
    'Dakshina Kannada',
    'Gadag',
    'Gulbarga',
    'Hassan',
    'Haveri district',
    'Kodagu',
    'Kolar',
    'Koppal',
    'Mandya',
    'Mysore',
    'Raichur',
    'Shimoga',
    'Tumkur',
    'Udupi',
    'Uttara Kannada',
    'Ramanagara',
    'Yadgir',
  ],
  'Kerala (KL)': [
    'Alappuzha',
    'Ernakulam',
    'Idukki',
    'Kannur',
    'Kasaragod',
    'Kollam',
    'Kottayam',
    'Kozhikode',
    'Malappuram',
    'Palakkad',
    'Pathanamthitta',
    'Thrissur',
    'Thiruvananthapuram',
    'Wayanad',
  ],
  'Madhya Pradesh (MP)': [
    'Alirajpur',
    'Anuppur',
    'Ashok Nagar',
    'Balaghat',
    'Barwani',
    'Betul',
    'Bhind',
    'Bhopal',
    'Burhanpur',
    'Chhatarpur',
    'Chhindwara',
    'Damoh',
    'Datia',
    'Dewas',
    'Dhar',
    'Dindori',
    'Guna',
    'Gwalior',
    'Harda',
    'Hoshangabad',
    'Indore',
    'Jabalpur',
    'Jhabua',
    'Katni',
    'Khandwa (East Nimar)',
    'Khargone (West Nimar)',
    'Mandla',
    'Mandsaur',
    'Morena',
    'Narsinghpur',
    'Neemuch',
    'Panna',
    'Rewa',
    'Rajgarh',
    'Ratlam',
    'Raisen',
    'Sagar',
    'Satna',
    'Sehore',
    'Seoni',
    'Shahdol',
    'Shajapur',
    'Sheopur',
    'Shivpuri',
    'Sidhi',
    'Singrauli',
    'Tikamgarh',
    'Ujjain',
    'Umaria',
    'Vidisha',
  ],
  'Maharashtra (MH)': [
    'Ahmednagar',
    'Akola',
    'Amravati',
    'Aurangabad',
    'Bhandara',
    'Beed',
    'Buldhana',
    'Chandrapur',
    'Dhule',
    'Gadchiroli',
    'Gondia',
    'Hingoli',
    'Jalgaon',
    'Jalna',
    'Kolhapur',
    'Latur',
    'Mumbai City',
    'Mumbai suburban',
    'Nandurbar',
    'Nanded',
    'Nagpur',
    'Nashik',
    'Osmanabad',
    'Parbhani',
    'Pune',
    'Raigad',
    'Ratnagiri',
    'Sindhudurg',
    'Sangli',
    'Solapur',
    'Satara',
    'Thane',
    'Wardha',
    'Washim',
    'Yavatmal',
  ],
  'Manipur (MN)': [
    'Bishnupur',
    'Churachandpur',
    'Chandel',
    'Imphal East',
    'Senapati',
    'Tamenglong',
    'Thoubal',
    'Ukhrul',
    'Imphal West',
  ],
  'Meghalaya (ML)': [
    'East Garo Hills',
    'East Khasi Hills',
    'Jaintia Hills',
    'Ri Bhoi',
    'South Garo Hills',
    'West Garo Hills',
    'West Khasi Hills',
  ],
  'Mizoram (MZ)': [
    'Aizawl',
    'Champhai',
    'Kolasib',
    'Lawngtlai',
    'Lunglei',
    'Mamit',
    'Saiha',
    'Serchhip',
  ],
  'Nagaland (NL)': [
    'Dimapur',
    'Kohima',
    'Mokokchung',
    'Mon',
    'Phek',
    'Tuensang',
    'Wokha',
    'Zunheboto',
  ],
  'Orissa (OR)': [
    'Angul',
    'Boudh (Bauda)',
    'Bhadrak',
    'Balangir',
    'Bargarh (Baragarh)',
    'Balasore',
    'Cuttack',
    'Debagarh (Deogarh)',
    'Dhenkanal',
    'Ganjam',
    'Gajapati',
    'Jharsuguda',
    'Jajpur',
    'Jagatsinghpur',
    'Khordha',
    'Kendujhar (Keonjhar)',
    'Kalahandi',
    'Kandhamal',
    'Koraput',
    'Kendrapara',
    'Malkangiri',
    'Mayurbhanj',
    'Nabarangpur',
    'Nuapada',
    'Nayagarh',
    'Puri',
    'Rayagada',
    'Sambalpur',
    'Subarnapur (Sonepur)',
    'Sundergarh',
  ],
  'Pondicherry (Puducherry) (PY)': ['Karaikal', 'Mahe', 'Pondicherry', 'Yanam'],
  'Punjab (PB)': [
    'Amritsar',
    'Barnala',
    'Bathinda',
    'Firozpur',
    'Faridkot',
    'Fatehgarh Sahib',
    'Fazilka',
    'Gurdaspur',
    'Hoshiarpur',
    'Jalandhar',
    'Kapurthala',
    'Ludhiana',
    'Mansa',
    'Moga',
    'Sri Muktsar Sahib',
    'Pathankot',
    'Patiala',
    'Rupnagar',
    'Ajitgarh (Mohali)',
    'Sangrur',
    'Nawanshahr',
    'Tarn Taran',
  ],
  'Rajasthan (RJ)': [
    'Ajmer',
    'Alwar',
    'Bikaner',
    'Barmer',
    'Banswara',
    'Bharatpur',
    'Baran',
    'Bundi',
    'Bhilwara',
    'Churu',
    'Chittorgarh',
    'Dausa',
    'Dholpur',
    'Dungapur',
    'Ganganagar',
    'Hanumangarh',
    'Jhunjhunu',
    'Jalore',
    'Jodhpur',
    'Jaipur',
    'Jaisalmer',
    'Jhalawar',
    'Karauli',
    'Kota',
    'Nagaur',
    'Pali',
    'Pratapgarh',
    'Rajsamand',
    'Sikar',
    'Sawai Madhopur',
    'Sirohi',
    'Tonk',
    'Udaipur',
  ],
  'Sikkim (SK)': ['East Sikkim', 'North Sikkim', 'South Sikkim', 'West Sikkim'],
  'Tamil Nadu (TN)': [
    'Ariyalur',
    'Chennai',
    'Coimbatore',
    'Cuddalore',
    'Dharmapuri',
    'Dindigul',
    'Erode',
    'Kanchipuram',
    'Kanyakumari',
    'Karur',
    'Madurai',
    'Nagapattinam',
    'Nilgiris',
    'Namakkal',
    'Perambalur',
    'Pudukkottai',
    'Ramanathapuram',
    'Salem',
    'Sivaganga',
    'Tirupur',
    'Tiruchirappalli',
    'Theni',
    'Tirunelveli',
    'Thanjavur',
    'Thoothukudi',
    'Tiruvallur',
    'Tiruvarur',
    'Tiruvannamalai',
    'Vellore',
    'Viluppuram',
    'Virudhunagar',
  ],
  'Tripura (TR)': [
    'Dhalai',
    'North Tripura',
    'South Tripura',
    'Khowai',
    'West Tripura',
  ],
  'Uttar Pradesh (UP)': [
    'Agra',
    'Allahabad',
    'Aligarh',
    'Ambedkar Nagar',
    'Auraiya',
    'Azamgarh',
    'Barabanki',
    'Budaun',
    'Bagpat',
    'Bahraich',
    'Bijnor',
    'Ballia',
    'Banda',
    'Balrampur',
    'Bareilly',
    'Basti',
    'Bulandshahr',
    'Chandauli',
    'Chhatrapati Shahuji Maharaj Nagar',
    'Chitrakoot',
    'Deoria',
    'Etah',
    'Kanshi Ram Nagar',
    'Etawah',
    'Firozabad',
    'Farrukhabad',
    'Fatehpur',
    'Faizabad',
    'Gautam Buddh Nagar',
    'Gonda',
    'Ghazipur',
    'Gorakhpur',
    'Ghaziabad',
    'Hamirpur',
    'Hardoi',
    'Mahamaya Nagar',
    'Jhansi',
    'Jalaun',
    'Jyotiba Phule Nagar',
    'Jaunpur district',
    'Ramabai Nagar (Kanpur Dehat)',
    'Kannauj',
    'Kanpur',
    'Kaushambi',
    'Kushinagar',
    'Lalitpur',
    'Lakhimpur Kheri',
    'Lucknow',
    'Mau',
    'Meerut',
    'Maharajganj',
    'Mahoba',
    'Mirzapur',
    'Moradabad',
    'Mainpuri',
    'Mathura',
    'Muzaffarnagar',
    'Panchsheel Nagar district (Hapur)',
    'Pilibhit',
    'Shamli',
    'Pratapgarh',
    'Rampur',
    'Raebareli',
    'Saharanpur',
    'Sitapur',
    'Shahjahanpur',
    'Sant Kabir Nagar',
    'Siddharthnagar',
    'Sonbhadra',
    'Sant Ravidas Nagar',
    'Sultanpur',
    'Shravasti',
    'Unnao',
    'Varanasi',
  ],
  'Uttarakhand (UK)': [
    'Almora',
    'Bageshwar',
    'Chamoli',
    'Champawat',
    'Dehradun',
    'Haridwar',
    'Nainital',
    'Pauri Garhwal',
    'Pithoragarh',
    'Rudraprayag',
    'Tehri Garhwal',
    'Udham Singh Nagar',
    'Uttarkashi',
  ],
  'West Bengal (WB)': [
    'Birbhum',
    'Bankura',
    'Bardhaman',
    'Darjeeling',
    'Dakshin Dinajpur',
    'Hooghly',
    'Howrah',
    'Jalpaiguri',
    'Cooch Behar',
    'Kolkata',
    'Maldah',
    'Paschim Medinipur',
    'Purba Medinipur',
    'Murshidabad',
    'Nadia',
    'North 24 Parganas',
    'South 24 Parganas',
    'Purulia',
    'Uttar Dinajpur',
  ],
}

export const GET_CITY_OPTIONS = (state) =>
  CITIES[state]?.map((city) => ({
    label: city,
    value: city,
  })) || ''

export const STATE_OPTIONS = Object.keys(CITIES).map((state) => ({
  label: state,
  value: state,
}))

export const INPUT_TYPES = (type) => {
  switch (type) {
    case 'input':
      return [
        'text',
        'phone',
        'email',
        'date',
        'dob',
        'anniversary',
        'companyName',
        'firstName',
        'spouseName',
        'lastName',
        'profession',
        ]
  
    case 'multi':
      return ['checkbox', 'image-multi']

    default:
      return []
  }
}