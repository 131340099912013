import React from 'react';
import CancelIcon from '../../cancel-icon.svg';
import PlusIcon from '../../plus.svg';

export const MediaUpload = ({
  handleImageChange,
  handleAddImageClick,
  removeImage,
  errorMessage,
  fileInputRef,
  images,
  questionId,
  disabled,
  showErrorMsg,
}) => {
  return (
    <div>
      <p
        style={{
          fontWeight: '300',
          color: '#808080',
          fontSize: '10px',
          fontStyle: 'italic',
        }}>
        (Max 5 images upto 10mb. *.png, .jpg, .jpeg supported)
      </p>
      {(errorMessage && showErrorMsg) && <p style={{ color: 'red' }}>{errorMessage}</p>}
      <div style={{display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridGap: '10px'}}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{ position: 'relative', margin: '10px' }}>
            <img
              src={URL.createObjectURL(image)}
              alt={`Uploaded ${index + 1}`}
              style={{ maxWidth: '100px', maxHeight: '100px' }}
            />
            <button
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                border: 'none',
                backgroundColor: 'transparent',
              }}
              onClick={() => removeImage(index, questionId)}>
              <div style={{ fontSize: '16px' }}>
                {' '}
                <img src={CancelIcon} />{' '}
              </div>
            </button>
          </div>
        ))}
        { !disabled &&<div
        style={{
          cursor: 'pointer',
          padding: '5px',
          border: '3px dashed #ccc',
          borderRadius: '3px',
          width: '100px',
          height: '100px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '10px',
        }}
        onClick={handleAddImageClick}>
        <div style={{ fontSize: '24px' }}>
          {' '}
          <img src={PlusIcon} />
        </div>
        <span style={{ fontSize: '13px' }}>Add Image</span>
      </div>}
      <input
        type='file'
        accept='.png, .jpg, .jpeg'
        multiple
        onChange={handleImageChange}
        ref={fileInputRef}
        style={{ display: 'none' }}
      />
      </div>
    </div>
  );
};