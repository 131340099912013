import React from "react";
import styled from "styled-components";

const $SurveyLabel = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: semi-bold;
`;

const $SurveyProgressContainer = styled.div`
  bottom: 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: #fff;
  color: #000;
  padding: 20px;
  margin-top: 20px;
`;

const ProgressBar = styled.div`
  background: rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 10px;
  width: 100%;
  position: relative;
  &:after {
    position: absolute;
    border-radius: 10px;
    content: "";
    width: ${(props) => props.progress}%;
    height: 100%;
    background: #2680eb;
  }
`;

const SurveyProgress = (props) => {
  const { currentStep, totalSteps } = props;
  const progress = Math.round((currentStep / totalSteps) * 100);
  return (
    <$SurveyProgressContainer>
      <$SurveyLabel>
        <div>Survey Progress</div>
        <div>{progress}%</div>
      </$SurveyLabel>
      <ProgressBar progress={progress} />
    </$SurveyProgressContainer>
  );
};

export default SurveyProgress;
