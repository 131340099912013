import styled, { css } from "styled-components";
import SurveyProgress from "./surveyComponents/SurveyProgress";

export const $SurveyContainer = styled.div`
  max-width: 480px;
  margin: auto;
  overflow-y: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.background || "#FFF"};
  font-family: ${(props) => props.fontFamily} !important;
  color: ${(props) => props.fontColor};
`;

export const $SurveyHeader = styled.div`
  text-align: center;
  margin-bottom: 12px;
  h1 {
    font-size: 28px;
  }
  h1,
  h4 {
    margin: 0;
  }
`;

export const $QuestContainer = styled.div`
  text-align: left;
  margin: 0 10px;
  p {
    flex: 1;
    font-weight: 600;
    margin: 0 0 8px;
  }
  span {
    margin: 5px;
  }
  input {
    border-radius: 4px;
  }
  input[type="checkbox"],
  input[type="radio"] {
    width: 18px;
    height: 18px;
    margin: 0;
    margin-right: 4px;
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
`;

export const $OpinionScaleBox = styled.div`
  border: 1px solid lightgray;
  display: inline-flex;
  background-color: white;
  border-radius: 4px;
  width: ${(props) => props.width || "50px"};
  height: ${(props) => props.height || "52px"};
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
  margin: 4px !important;
  color: #000;
  font-weight: bold;
  ${(props) =>
    props.isActive &&
    css`
      background-color: #2680eb;
      border-color: #2680eb;
      color: #fff;
      box-shadow: rgba(38, 128, 235, 0.35) 0px 6px 10px;
    `};
`;

export const $Rating = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin: 0 2px 4px !important;
  img {
    width: 50px;
  }
`;

export const $SurveyInput = styled.input`
  width: 100%;
  height: 40px;
  padding: 5px 12px;
  border-radius: 2px;
  border: 1px solid ${(props) => (props.isError ? "tomato" : "lightgray")};
  &:focus {
    outline: none;
  }
`;

export const $ImageContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 1%;
  width: 30%;
  max-width: 120px;
  min-width: 80px;
  > div {
    small {
      display: block;
      font-size: 14px;
      text-align: center;
      max-height: 44px;
      overflow: hidden;
      margin: 4px 0;
    }
    > div {
      img {
        display: block;
        width: 100%;
        height: 100px;
        border-radius: 4px;
        cursor: pointer;
        object-fit: contain;
        background-color: #fff;
        margin-bottom: 20px;
      }
      background-color: #fff;
      padding: 5px;
      border-radius: 4px;
      svg.sic {
        display: block;
        margin: auto;
        margin-bottom: 10px;
      }
      ${(props) =>
        props.isChecked &&
        css`
          background-color: #2680eb;
          border-color: #2680eb;
          color: #fff;
          box-shadow: rgba(38, 128, 235, 0.35) 0px 6px 10px;
          svg.sic {
            path {
              fill: #fff;
            }
          }
        `};
    }
  }
`;

export const $BannerContainer = styled.img`
  max-width: 100%;
  max-height: 175px;
  width: 100%;
  object-fit: contain;
`;

export const $FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
  gap: ${(props) => props.gap};
  flex-direction: ${(props) => props.direction};
  cursor: pointer;
  padding: ${(props) => props.padding};
`;

export const $Block = styled.div`
  overflow-x: ${(props) => props.overflowX};
  overflow-y: ${(props) => props.overflowY};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  color: ${(props) => props.color};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const $Submit = styled.button`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.bgColor};
  font-family: ${(props) => props.fontFamily};
  font-weight: ${(props) => props.fontWeight};
  border: none;
  padding: 8px;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 35%) 0px 6px 10px;
  font-size: 20px;
  width: 140px;
  margin: ${(props) => (props.isSinglePage ? "30px 0 20px" : "0 0 20px")};
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    opacity: 0.6;
  }
  &:active {
    box-shadow: inset rgb(0 0 0 / 35%) 0px 2px 5px;
  }
`;

export const $SingleQuest = styled.div`
  min-height: 200px;
`;

export const $Message = styled($FlexBox)`
  font-size: 24px;
  color: #043040;
  text-align: center;
  font-weight: 500;
  height: 80vh;
  width: 100vw;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;
export const $ImagePage = styled($FlexBox)`
  position: relative;
  max-width: 480px;
  margin: auto;
  overflow-y: auto;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    width: 100%;
    margin: auto;
  }
  button {
    position: absolute;
    bottom: 0;
    width: 180px;
  }
`;
