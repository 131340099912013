import React from 'react'
import Select from 'react-select'

export const ReactSelect = ({
  isDisabled,
  options,
  value,
  onChange,
  placeholder,
}) => {
  return (
      <Select
        isSearchable={false}
        isDisabled={isDisabled}
        options={options}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        styles={{
          menuList: (provided, state) => ({
            ...provided,
            maxHeight: '140px'
          }),
        }}
      />
  )
}
