import React, { useMemo } from "react";
import { $QuestContainer } from "../Survey.styled";
import { SurveyQuestion } from "./index";

export const SurveyItem = ({
  surveyForm,
  setSurveyForm,
  body,
  quest,
  error,
  setError,
  setMediaUploadSurvey,
  ignoreQuestionMap,
  setIgnoreQuestionMap,
}) => {
  const currentQuestionIndex = body
    ?.filter((ques) => ques.fieldType === "question")
    ?.findIndex((qu) => qu._id === quest?._id);
  const displayQuestNumber =
    body
      ?.slice(0, currentQuestionIndex)
      ?.filter(
        (quest) =>
          !Object.values(ignoreQuestionMap).flat().includes(quest?._id),
      )?.length + 1;
  const questNumber = currentQuestionIndex + 1;

  const getSurveyComponent = (fieldType) => {
    switch (fieldType) {
      case "html":
        return (
          <$QuestContainer>
            <div dangerouslySetInnerHTML={{ __html: quest?.text }} />
          </$QuestContainer>
        );

      case "question":
        return (
          <SurveyQuestion
            key={quest?._id}
            quest={quest}
            displayQuestNumber={displayQuestNumber}
            questNumber={questNumber}
            error={error}
            setError={setError}
            surveyForm={surveyForm}
            setSurveyForm={setSurveyForm}
            setMediaUploadSurvey={setMediaUploadSurvey}
            ignoreQuestionMap={ignoreQuestionMap}
            setIgnoreQuestionMap={setIgnoreQuestionMap}
          />
        );
    }
  };

  return <div>{getSurveyComponent(quest?.fieldType)}</div>;
};
