import React from 'react'
import { $ImageContainer } from '../Survey.styled'

export const ImageSelect = ({
  questId,
  optionId,
  type,
  option,
  setOption,
  selectedOption,
  imageTitle,
  showTitle = false
}) => {
  const inputType = type === 'image' ? 'radio' : 'checkbox'

  const selectOption = () => {
    if (inputType === 'radio') {
      setOption(questId, optionId)
    } else {
      selectedOption?.includes(optionId)
        ? setOption(questId, selectedOption?.filter(opt => opt !== optionId))
        : setOption(questId, [...selectedOption, optionId])
    }
  }

  const isChecked = inputType === 'radio'
    ? optionId === selectedOption
    : selectedOption?.includes(optionId)

  return (
    <$ImageContainer onClick={selectOption} isChecked={isChecked}>
      <div>
        {/* <input readOnly name={questId} type={inputType} value={selectedOption} checked={isChecked} /> */}
        <div>
          <img src={option} />
          {SVG}
        </div>
        {showTitle && (<small title={imageTitle}> {imageTitle} </small>)}
      </div>
    </$ImageContainer>
  )
}


export const SVG = <svg class="sic" width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.8943 1.756L18.2593 0.11C18.2257 0.0750386 18.1853 0.0473305 18.1406 0.0285838C18.0959 0.00983715 18.0478 0.000449196 17.9993 0.000999733V0.000999733C17.9507 -0.000419254 17.9024 0.00857303 17.8575 0.0273726C17.8127 0.0461723 17.7724 0.0743446 17.7393 0.11L6.4083 11.289L2.2843 7.25C2.25041 7.21541 2.20996 7.18793 2.16531 7.16917C2.12067 7.15041 2.07273 7.14075 2.0243 7.14075C1.97587 7.14075 1.92794 7.15041 1.88329 7.16917C1.83865 7.18793 1.79819 7.21541 1.7643 7.25L0.1113 8.869C0.0761393 8.90236 0.0481383 8.94252 0.0290031 8.98705C0.009868 9.03158 0 9.07953 0 9.128C0 9.17646 0.009868 9.22442 0.0290031 9.26895C0.0481383 9.31348 0.0761393 9.35364 0.1113 9.387L5.3113 14.481C5.59598 14.7837 5.98388 14.9686 6.3983 14.999C6.80489 14.964 7.18618 14.787 7.4753 14.499H7.4843L19.9033 2.274C19.9669 2.20222 20.0013 2.10917 19.9997 2.01325C19.998 1.91733 19.9604 1.82553 19.8943 1.756V1.756Z" fill="#B9B9B9"/>
</svg>
