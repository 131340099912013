import React from 'react'
import { $FlexBox } from '../Survey.styled'

export const Radio = ({
  questId,
  optionId,
  option,
  setOption,
  inputType,
  selectedOption
}) => {

  const selectOption = () => {
    if (inputType === 'radio') {
      setOption(questId, optionId)
    } else {
      selectedOption?.includes(optionId)
      ? setOption(questId, selectedOption?.filter(opt => opt !== optionId))
      : setOption(questId, [...selectedOption, optionId])
    }
  }

  const isChecked = inputType === 'radio'
    ? optionId === selectedOption
    : selectedOption?.includes(optionId)

  return (
    <$FlexBox gap="8px" padding="0 0 4px 0" onClick={selectOption} align="center" justify="flex-start">
      <input readOnly value={selectedOption} name={questId} type={inputType} checked={isChecked} />
      <label htmlFor={questId}> {option} </label>
    </$FlexBox>
  )
}
