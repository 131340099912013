import React from 'react'
import { $SurveyHeader } from '../Survey.styled'

export const SurveyHeader = (props) => {
  return (
    <$SurveyHeader>
      <h1 dangerouslySetInnerHTML={{ __html: props.survey?.header }} />
      <h4 dangerouslySetInnerHTML={{ __html: props.survey?.description }} />
    </$SurveyHeader>
  )
}
